import { datadogLogs as ddLogs } from '@datadog/browser-logs'; // https://docs.datadoghq.com/logs/log_collection/javascript/
import { datadogRum as ddRum } from '@datadog/browser-rum';
import type { LogMetadata } from '@knapsack/logger';

export { ddLogs, ddRum };

export const commonDataDogConfig = {
  site: 'datadoghq.com',
  service: 'app-ui',
  env: process.env.NEXT_PUBLIC_ENV || 'development',
  version: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
  // Options that must have a matching configuration with the RUM and Logs SDKs:
  sessionPersistence: 'cookie',
  useSecureSessionCookie: true,
} as const;

export const logError = (error: Error) => {
  console.error(error);
  ddLogs.logger.error(error.message, { error }, error);
  ddRum.addError(error);
};

export const clearUser = () => {
  ddLogs.clearUser();
  ddRum.clearUser();
};

export const setUserId = (userId: string) => {
  ddLogs.setUser({ id: userId });
  ddRum.setUser({ id: userId });
};

export const setGlobalContext = (context: LogMetadata) =>
  ddLogs.setGlobalContext(context);

export const setGlobalContextProperty = <K extends keyof LogMetadata>(
  key: K,
  value: LogMetadata[K],
) => {
  ddLogs.setGlobalContextProperty(key, value);
  ddRum.setGlobalContextProperty(key, value);
};
