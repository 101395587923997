import { KsAppClientDataNoMeta } from '@knapsack/types';
import { type Draft } from '@knapsack/utils';
import { duplicateBlocks } from './utils.blocks.xstate';

export function duplicatePage({
  pageId,
  newId,
  newTitle,
  data,
}: {
  pageId: string;
  newId: string;
  newTitle: string;
  data: Draft<KsAppClientDataNoMeta>;
}): void {
  const page = data.customPagesState.pages[pageId];
  if (!page) {
    throw new Error(`Page not found: ${pageId}`);
  }
  const { newBlockIds } = duplicateBlocks({
    blockIds: page.blockIds || [],
    data,
  });
  data.customPagesState.pages[newId] = {
    ...page,
    id: newId,
    title: newTitle,
    blockIds: newBlockIds,
  };
}
