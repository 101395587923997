import type {
  KnapsackPattern,
  KnapsackPatternTemplate,
  KsAppClientDataNoMeta,
  PageTab,
} from '@knapsack/types';
import { makeShortId, type Draft } from '@knapsack/utils';
import { duplicateBlocks } from './utils.blocks.xstate';
import { duplicateDemos } from './utils.demos.xstate';

export function duplicatePattern({
  patternId,
  newPatternId,
  newPatternTitle,
  includeTabIds,
  userId,
  data,
}: {
  patternId: string;
  newPatternId: string;
  newPatternTitle: string;
  includeTabIds?: string[];
  userId: string;
  data: Draft<KsAppClientDataNoMeta>;
}): void {
  const pattern = data.patternsState.patterns[patternId];
  if (!pattern) {
    throw new Error(`Could not find pattern "${patternId}"`);
  }

  const duplicatedTemplates: KnapsackPatternTemplate[] = [];
  const duplicatedTabs: PageTab[] = [];
  const duplicatedSubPages: KnapsackPattern['subPages'] = [];
  const duplicatedDesignSrcs: KnapsackPattern['designSrcComponentsById'] = {};

  // go by tab order to keep the same order
  for (const tab of pattern.tabs) {
    if (includeTabIds?.includes(tab.id)) {
      const newId = makeShortId();
      duplicatedTabs.push({
        ...tab,
        id: newId,
      });

      switch (tab.type) {
        case 'designSrc': {
          const designSrc = pattern.designSrcComponentsById[tab.id];
          if (!designSrc) continue;
          duplicatedDesignSrcs[newId] = {
            ...designSrc,
            id: newId,
          };
          break;
        }
        case 'template': {
          const template = pattern.templates.find((t) => t.id === tab.id);
          if (!template) continue;
          const newTemplate: KnapsackPatternTemplate = {
            ...template,
            id: newId,
            blockIds: [], // will be set below
            demoIds: [], // will be set below
          };
          if (template.blockIds.length > 0) {
            const { newBlockIds } = duplicateBlocks({
              blockIds: template.blockIds,
              data,
            });

            newTemplate.blockIds = newBlockIds;
          }
          if (template.demoIds.length > 0) {
            const { newDemoIds } = duplicateDemos({
              demoIds: template.demoIds,
              demosById: data.db.demos.byId,
              newPatternId,
              newTemplateId: newId,
              userId,
            });
            newTemplate.demoIds = newDemoIds;
          }
          duplicatedTemplates.push(newTemplate);
          break;
        }
        case 'subPage': {
          const subPage = pattern.subPages.find((s) => s.id === tab.id);
          if (!subPage) continue;
          const newSubPage: KnapsackPattern['subPages'][0] = {
            ...subPage,
            id: newId,
            blockIds: [], // will be set below
          };
          if (subPage.blockIds.length > 0) {
            const { newBlockIds } = duplicateBlocks({
              blockIds: subPage.blockIds,
              data,
            });
            newSubPage.blockIds = newBlockIds;
          }
          duplicatedSubPages.push(newSubPage);
          break;
        }
        default: {
          const _exhaustiveCheck: never = tab.type;
        }
      }
    }
  }
  data.patternsState.patterns[newPatternId] = {
    ...pattern,
    id: newPatternId,
    title: newPatternTitle,
    designSrcComponentsById: duplicatedDesignSrcs,
    subPages: duplicatedSubPages,
    templates: duplicatedTemplates,
    tabs: duplicatedTabs,
  };
}
