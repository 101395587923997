import { Draft } from 'immer';
import { KsAppClientDataNoMeta } from '@/types';
import { KsFile } from '@knapsack/types';
import {
  SET_APP_CLIENT_DATA,
  SetAppClientData,
  RESET_APP_CLIENT_DATA,
  ResetAppClientData,
} from './shared.xstate';

export type FilesState = KsAppClientDataNoMeta['filesState'];

export const filesInitialState: FilesState = {
  files: {},
};

export type FilesActions =
  | SetAppClientData
  | ResetAppClientData
  | {
      type: 'knapsack/files/ADD';
      file: KsFile;
    }
  | {
      type: 'knapsack/files/DELETE';
      blockId: string;
      fileId: string;
    }
  | {
      type: 'knapsack/files/UPDATE';
      fileId: string;
      file: Partial<Omit<KsFile, 'id'>>;
    };

export default function reducer(
  data: Draft<KsAppClientDataNoMeta>,
  action: FilesActions,
): FilesState {
  switch (action.type) {
    case SET_APP_CLIENT_DATA:
      data.filesState = action.payload.filesState || filesInitialState;
      return;
    case RESET_APP_CLIENT_DATA:
      data.filesState = filesInitialState;
      return;
    case 'knapsack/files/ADD': {
      data.filesState.files[action.file.id] = action.file;
      return;
    }
    case 'knapsack/files/DELETE': {
      delete data.filesState.files[action.fileId];
      return;
    }
    case 'knapsack/files/UPDATE': {
      data.filesState.files[action.fileId] = {
        ...data.filesState.files[action.fileId],
        ...action.file,
      };
    }
  }
}
