import type { KsAppClientDataNoMeta } from '@knapsack/types';
import type { Draft } from '@knapsack/utils';
import { navByIdToArray } from '@/domains/navs/utils/nav-data';

export function duplicateNavItem({
  navItemId,
  newNavItemId,
  newNavItemName,
  data,
}: {
  navItemId: string;
  newNavItemId: string;
  newNavItemName: string;
  data: Draft<KsAppClientDataNoMeta>;
}): void {
  const { navsState } = data;
  const originalNavItem = navByIdToArray(navsState.byId, navsState.order).find(
    (nav) => nav.id === navItemId,
  );
  if (!originalNavItem) {
    throw new Error(`original nav item not found - ${navItemId}`);
  }
  navsState.byId[newNavItemId] = {
    ...originalNavItem,
    id: newNavItemId,
    path: originalNavItem.path.replace(navItemId, newNavItemId),
    name: newNavItemName,
  };
  navsState.order.splice(
    navsState.order.indexOf(navItemId) + 1,
    0,
    newNavItemId,
  );
}
