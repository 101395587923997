import { jsx as _jsx } from "react/jsx-runtime";
import ReactMarkdown from 'react-markdown';
import { TextBody, TextHeading } from '../text/index.js';
/**
 * Convert markdown to styled text
 */
export const Markdown = ({ content, paragraphSpacing, }) => {
    return (_jsx(ReactMarkdown, { components: {
            h1: ({ children }) => (_jsx(TextHeading, { tag: "h1", size: "large", children: children })),
            h2: ({ children }) => (_jsx(TextHeading, { tag: "h2", size: "medium", children: children })),
            h3: ({ children }) => (_jsx(TextHeading, { tag: "h3", size: "small", children: children })),
            h4: ({ children }) => (_jsx(TextHeading, { tag: "h4", size: "xsmall", children: children })),
            p: ({ children }) => (_jsx(TextBody, { spacing: paragraphSpacing, children: children })),
        }, children: content }));
};
