import type { ApiRoute } from '@knapsack/types';

export type QueryParamsInput = Record<
  string,
  string | number | boolean | undefined | null | string[] | URL
>;

/**
 * Creates a URL with query parameters, supporting both full URLs and relative paths
 * @example
 * ```ts
 * createUrlWithParams({
 *   url: 'https://example.com',
 *   queryParams: { foo: 'bar', count: 42, tags: ['one', 'two'] }
 * })
 * // => URL('https://example.com?foo=bar&count=42&tags=one%20two')
 */
export function createUrlWithParams<Params extends QueryParamsInput>({
  /** Can either be a full URL or a relative one (but that requires passing in "origin") */
  url,
  /** Base URL to use when url is a relative path */
  origin,
  /**
   * URL's will be stringified
   * Arrays of strings will be joined with a space
   * Other values will be stringified
   */
  queryParams,
}: {
  /**
   * Can either be a full URL or a relative one (but that requires passing in "origin")
   */
  url: string | URL;
  /**
   * URL's will be stringified
   * Arrays of strings will be joined with a space
   * Other values will be stringified
   */
  queryParams: Params;
  origin?: string;
}) {
  if (!origin && typeof url === 'string' && !url.startsWith('http')) {
    throw new Error(
      `When using "createUrl" either pass in a full "url" or provide an "origin"`,
    );
  }

  const u =
    // eslint-disable-next-line no-nested-ternary
    typeof url === 'string'
      ? new URL(url, origin)
      : origin
      ? new URL(url.toString(), origin)
      : url;
  Object.entries(queryParams ?? {}).forEach(([key, value]) => {
    if (value === undefined || value === null) return;
    if (value instanceof URL) {
      u.searchParams.set(key, value.toString());
    } else if (Array.isArray(value)) {
      u.searchParams.set(key, value.join(' '));
    } else {
      const v = `${value}`;
      if (v === '') return;
      u.searchParams.set(key, v);
    }
  });
  return u;
}

/**
 * Creates a URL for a specific API route with typed query parameters
 * @example
 * ```ts
 * createUrlForRoute({
 *   path: '/api/users',
 *   queryParams: { id: '123' },
 *   origin: 'https://example.com'
 * })
 * // => URL('https://example.com/api/users?id=123')
 */
export function createUrlForRoute<Route extends ApiRoute<any>>({
  /** API route path */
  path,
  /** Typed query parameters matching the route's input type */
  queryParams,
  /** Base URL for relative paths */
  origin,
}: {
  path: Route['path'];
  queryParams: Route['input'] extends Record<string, unknown>
    ? Route['input']
    : undefined;
  origin?: string;
}) {
  return createUrlWithParams({
    url: path,
    queryParams,
    origin,
  });
}

/**
 * Converts URLSearchParams into a typed object
 * @example
 * ```ts
 * const params = new URLSearchParams('foo=bar&count=42');
 * getParams(params)
 * // => { foo: 'bar', count: '42' }
 */
export function getParams<Params extends Record<string, string>>(
  /** URLSearchParams object to convert */
  params: URLSearchParams,
): Params {
  return Object.fromEntries(params.entries()) as Params;
}

/**
 * Converts URLSearchParams into a typed object matching an API route's input type
 * @example
 * ```ts
 * const params = new URLSearchParams('userId=123&action=update');
 * getParamsForRoute<UserRoute>(params)
 * // => { userId: '123', action: 'update' }
 */
export function getParamsForRoute<Route extends ApiRoute<any>>(
  /** URLSearchParams object to convert */
  params: URLSearchParams,
): Route['input'] {
  return getParams(params);
}
