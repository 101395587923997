'use client';

import type { LogMetadata } from '@knapsack/logger';
import { datadogLogs as ddLogs } from '@datadog/browser-logs'; // https://docs.datadoghq.com/logs/log_collection/javascript/
import { isBrowser } from '@knapsack/utils';
import { commonDataDogConfig } from './datadog';

const clientToken = process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN;

if (isBrowser && clientToken) {
  // @see: https://docs.datadoghq.com/logs/log_collection/javascript/#initialization-parameters
  ddLogs.init({
    ...commonDataDogConfig,
    clientToken,
    forwardErrorsToLogs: true,
    sessionSampleRate: 100, // percent
    telemetrySampleRate: 0, // percent of events to send to Datadog for their telemetry tracking
    // should give us info in login popup. might need to clear context in situations though
    storeContextsAcrossPages: true,

    /**
     * Return `false` to prevent the event from being sent to Datadog.
     * Can mutate the `event` object safely
     * Note that `event.http.url` is the URL of the HTTP request that triggered the event
     * Note that `event.view.url` is the URL of the current page
     * Note that `event.view.referrer` is the URL of the page that referred to the current page
     */
    beforeSend(event, _context) {
      if (event.http?.url) {
        const u = new URL(event.http.url);
        if (
          ['localhost', '127.0.0.1', 'app.launchdarkly.com'].includes(
            u.hostname,
          )
        ) {
          return false;
        }
        if (
          u.hostname === window.location.hostname &&
          u.pathname === '/monitoring'
        ) {
          return false;
        }
        if (u.searchParams.has('isOkToFail')) return false;
      }

      return true;
    },
  });
}

export const log = {
  info: (message: string, metadata?: LogMetadata) => {
    console.log(message, metadata);
    ddLogs.logger.info(message, metadata);
  },
  warn: (message: string, metadata?: LogMetadata) => {
    console.warn(message, metadata);
    ddLogs.logger.warn(message, metadata);
  },
  error: (message: string, metadata?: LogMetadata) => {
    console.error(message, metadata);
    ddLogs.logger.error(message, metadata);
  },
};
