import { jsx as _jsx } from "react/jsx-runtime";
import clsx from 'clsx';
import { textHeadingStyles } from './text-heading.css.js';
import { assignThemeVars } from '../text.utils.js';
import { textTheme } from '../text.theme.css.js';
export const TextHeading = ({ align, children, className, color, display, fontFamily = 'sans', size = 'medium', spacing, tag, testId, theme, transform, truncate, weight = 'semibold', }) => {
    let TextTag;
    if (tag) {
        TextTag = `${tag}`;
    }
    else {
        switch (size) {
            case 'xlarge':
                TextTag = 'h2';
                break;
            case 'large':
                TextTag = 'h3';
                break;
            case 'medium':
                TextTag = 'h4';
                break;
            case 'small':
                TextTag = 'h5';
                break;
            case 'xsmall':
                TextTag = 'h6';
                break;
            default:
                TextTag = `${tag}`;
        }
    }
    return (_jsx(TextTag, { className: clsx([
            textHeadingStyles({
                align,
                color,
                display,
                fontFamily,
                size,
                spacing,
                transform,
                truncate,
                weight,
            }),
            className,
        ]), "data-testid": testId, style: assignThemeVars(textTheme, theme), children: children }));
};
