import type { KsPluginLoadFunc, PluginMeta } from '@knapsack/app/types';
import { plugins } from './plugins-store';

export interface LoadPluginResult {
  ok: boolean;
  meta: PluginMeta;
  errorTitle?: string;
  errorDetails?: string;
}

export async function loadPlugin({
  pluginMeta,
  urlBase,
}: {
  pluginMeta: PluginMeta;
  urlBase: string;
}): Promise<LoadPluginResult> {
  if (!pluginMeta.clientPluginPath) {
    return {
      ok: true,
      meta: pluginMeta,
    };
  }
  try {
    const isAbsolute =
      pluginMeta.clientPluginPath.startsWith('http') ||
      pluginMeta.clientPluginPath.startsWith('//');
    const importUrl = isAbsolute
      ? pluginMeta.clientPluginPath
      : new URL(pluginMeta.clientPluginPath, urlBase).toString();
    const pluginRegister: KsPluginLoadFunc = await import(
      /* @vite-ignore */
      /* webpackIgnore: true */
      importUrl
    ).then((mod) => mod?.default);

    if (!pluginRegister || typeof pluginRegister !== 'function') {
      const msg = `Could not load plugin "${pluginMeta.id}" - trying to "import('${importUrl}')" - received file but did not find a default export `;
      console.error(msg);
      return {
        ok: false,
        errorTitle: msg,
        meta: pluginMeta,
      };
    }
    const plugin = await pluginRegister({ pluginMeta });
    plugins.register(plugin);
    if (pluginMeta.cssPath) {
      try {
        const cssId = `plugin-css--${pluginMeta.id}`;
        if (!document.getElementById(cssId)) {
          const head = document.getElementsByTagName('head')[0];
          const link = document.createElement('link');
          link.id = cssId;
          link.rel = 'stylesheet';
          link.type = 'text/css';
          link.href = pluginMeta.cssPath;
          link.media = 'all';
          head.appendChild(link);
          // console.log(`added css file: ${pluginMeta.cssPath}`);
        }
      } catch (e) {
        console.error(e);
      }
    }
    return {
      ok: true,
      meta: pluginMeta,
    };
  } catch (error) {
    if (error instanceof Error) {
      const msg = `Could not load plugin "${pluginMeta.id}" - trying to "import('${pluginMeta.clientPluginPath}')" ${error.message} `;
      console.error(error);
      console.error(msg);
      return {
        ok: false,
        errorTitle: msg,
        errorDetails: error.stack,
        meta: pluginMeta,
      };
    }
  }
}

export async function loadPlugins({
  pluginMetas,
  urlBase,
}: {
  pluginMetas: PluginMeta[];
  urlBase: string;
}): Promise<LoadPluginResult[]> {
  return Promise.all(
    pluginMetas.map((pluginMeta) => loadPlugin({ pluginMeta, urlBase })),
  );
}

export const resetPlugins = (): void => plugins.resetPlugins();
