import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { toast } from 'react-toastify';
import { ButtonTertiary } from '../button/index.js';
import { Icon } from '../icon/index.js';
import { Markdown } from '../markdown/markdown.js';
import { TextBody } from '../text/index.js';
import { toastAction, toastContent } from './toast.css.js';
const styledContent = ({ action, message, title, type = 'info', preventLog, }) => {
    if (!preventLog) {
        const msg = `Toast Message: ${title === undefined ? message : `${title} ${message}`}`;
        switch (type) {
            case 'error':
                console.error(msg);
                break;
            case 'info':
            case 'warning':
                console.warn(msg);
                break;
            case 'success':
                console.log(msg);
                break;
        }
    }
    return (_jsxs("div", { className: toastContent, "data-testid": `toast-${type}`, children: [_jsx("span", { style: { color: `var(--toastify-color-${type})`, paddingBlock: '2px' }, children: _jsx(Icon, { symbol: `state-${type}` }) }), _jsxs("span", { children: [title && (_jsx(TextBody, { weight: "medium", spacing: "xsmall", children: title })), typeof message === 'string' ? (_jsx(Markdown, { content: message, paragraphSpacing: "small" })) : (message), action && (_jsx(ButtonTertiary, { className: toastAction, label: action.label, onTrigger: action.onTrigger, size: "small", color: "subtle" }))] })] }));
};
export const dismissAllToasts = () => toast.dismiss();
export const dismissToast = (id) => toast.dismiss(id);
export const isToastActive = (id) => toast.isActive(id);
export const updateToast = (id, props) => toast.update(id, {
    ...props,
    render: styledContent({
        action: props.action,
        message: props.message,
        preventLog: props.preventLog,
        title: props.title,
        type: props.type,
    }),
});
export function createToast({ action, autoClose, message, onClose, preventLog, title, type = 'info', id, }) {
    const toastId = id || `${Math.floor(Math.random() * 1200)}`;
    const autoCloseFallback = type === 'error' ? false : 5_000;
    toast(styledContent({ action, message, preventLog, title, type }), {
        autoClose: autoClose === false || typeof autoClose === 'number'
            ? autoClose
            : autoCloseFallback,
        draggable: false,
        icon: false,
        toastId,
        type,
    });
    toast.onChange((payload) => {
        if (payload.status === 'removed') {
            onClose?.();
        }
    });
    return {
        toastId,
        dismissToast: () => dismissToast(toastId),
        dismissAllToasts: () => dismissAllToasts(),
        isToastActive: () => isToastActive(toastId),
    };
}
