import { send, StateNodeConfig } from 'xstate';
import { assign } from '@xstate/immer';
import { hasuraGql } from '@/services/hasura-client';
import { PromiseResult } from '@/utils/type-utils';
import {
  login,
  logout,
  getAccessToken,
  setAccessTokenInMem,
} from '@/domains/users/auth/client';
import { isBrowser } from '@knapsack/utils';
import { isCypress } from '@/utils/constants';
import { createToast } from '@knapsack/toby';
import {
  clearUser,
  setGlobalContextProperty,
  setUserId,
} from '@/services/datadog';
import { expandSitesByRole } from '@knapsack/core';
import { getSecondsLeftUntilExpiresAt } from '@/domains/users/auth';
import { uiMachine } from '../../ui/ui.xstate';
import {
  AppEvents,
  AppContext,
  AppStateSchema,
  createInvokablePromise,
  APP_SUB_MACHINE_IDS,
} from '../app.xstate-utils';
import { getUserRoleOverride } from '../../../../utils/user-override-utils';
import type { UiEvents } from '../../ui/types';
import type { AppClientDataEvents } from '../../app-client-data';
import { userChangesBroadcastChannel } from './user-changes-broadcast-channel';

/**
 * Fetch user from db
 */
async function loadUser({ userId, email }: { userId: string; email: string }) {
  const today = new Date().toISOString();
  if (!userId) throw new Error(`Load User missing userId`);
  if (!email) throw new Error(`Load User missing email`);
  try {
    const res = await hasuraGql.UpdateAndGetUser({
      email,
      userId,
      today,
    });
    return res.user;
  } catch (e) {
    console.error(e);
    throw new Error(
      `Loading User Details failed. userId probably does not exist in our DB: "${userId}", or auth token is bad.`,
    );
  }
}

export const userStateConfig: StateNodeConfig<
  AppContext,
  AppStateSchema['states']['user'],
  AppEvents
> = {
  id: 'user',
  initial: 'unknown',
  strict: true,
  states: {
    unknown: {
      invoke: createInvokablePromise<{ user?: AppContext['user'] }>({
        id: 'authPromiseChecker',
        src: async () => {
          // Just in case
          if (!isBrowser) {
            // it's ok, we'll just stay in the unknown state
            // might be b/c of SSR or Unit Tests
            return {};
          }

          if (isCypress()) {
            const { getCypressSessionConfig } = await import(
              '@/domains/users/utils/cypress-session-config'
            );
            const {
              user: { userRole },
            } = await getCypressSessionConfig();

            if (userRole === 'ANONYMOUS') return {};

            return {
              user: {
                userId: `CYPRESS_${userRole}_ID`,
                email: `CYPRESS_${userRole}@KNAPSACK.CLOUD`,
                emailVerified: true,
                isSuperAdmin: false,
                authStatus: {
                  expiresAt: 9999999999,
                  timesRefreshed: 0,
                },
                // having more than one so when we visit `/` we do not get redirected to only siteId in array
                membershipSiteIds: ['ks-sandbox', 'ks-test'],
                // note that this is only going to work for `ks-sandbox` site
                getSiteRole: (siteId) =>
                  siteId === 'ks-sandbox' ? userRole : 'ANONYMOUS',
              },
            };
          }
          const userRes = await getAccessToken();
          //
          switch (userRes.type) {
            case 'error': {
              const { error, meta } = userRes;
              console.error(error, meta);
              throw new Error(error);
            }
            case 'noSession':
              return {};
            case 'userNeedsToLoginAgain':
              throw new Error('User Should Log in again');
            case 'success': {
              const { accessToken, expiresAt } = userRes;
              setAccessTokenInMem(accessToken);

              const { email, isSuperAdmin, sitesByRole, userId, picture } =
                userRes.user;

              const roleOverride = getUserRoleOverride();
              const siteRoleMap = expandSitesByRole(sitesByRole);

              return {
                user: {
                  email,
                  userId,
                  isSuperAdmin,
                  membershipSiteIds: Object.keys(siteRoleMap ?? {}).sort(),
                  authStatus: {
                    expiresAt,
                    timesRefreshed: 0,
                  },
                  getSiteRole:
                    isSuperAdmin && roleOverride
                      ? () => roleOverride
                      : (siteId) => {
                          if (isSuperAdmin) return 'ADMIN';
                          return siteRoleMap[siteId] || 'ANONYMOUS';
                        },
                },
              } satisfies { user: AppContext['user'] };
            }
            default: {
              const _exhaustiveCheck = userRes;
              throw new Error(JSON.stringify(userRes));
            }
          }
        },
        onDone: [
          {
            cond: (_, event) => !!event.data?.user,
            target: 'loggedIn',
            actions: assign((ctx, { data }) => {
              ctx.user = data.user;
            }),
          },
          { target: 'loggedOut' },
        ],
        onErrorTarget: 'loggedOut',
        onErrorActions: [
          assign((ctx, { data: error }) => {
            ctx.userAuthError = {
              title: 'User Error',
              description: error.message,
            };
          }),
          {
            type: 'sendUserMessage',
            exec(_, { data: error }) {
              createToast({
                type: 'error',
                title: 'User Error',
                message: error.message,
              });
            },
          },
        ],
      }),
    },
    loggedOut: {
      invoke: {
        id: 'userChangesBroadcastChannel.loggedOut',
        src: () => () => {
          if (!isBrowser) return () => {};
          userChangesBroadcastChannel.postMessage({
            type: 'broadcast.user.loggedOut',
          });
          // returns a cleanup function that will remove the listener
          return userChangesBroadcastChannel.addMessageListener(
            'broadcast.user.loggedIn',
            () => window.location.reload(),
          );
        },
      },
      on: {
        'user.signIn': {
          actions: (_, { connection }) => {
            if (isCypress()) {
              throw new Error('Cypress login not supported');
            }
            // performs a hard navigation page load
            login({ connection });
          },
        },
      },
    },
    loggedIn: {
      initial: 'loadingDetails',
      entry: [
        send(
          (): UiEvents => ({
            type: 'user.stateChanged',
            isLoggedIn: true,
          }),
          { to: uiMachine.id },
        ),
        (ctx) => setUserId(ctx.user.userId),
      ],
      exit: [
        assign((ctx) => {
          ctx.user = null;
        }),
        send(
          (): UiEvents => ({
            type: 'user.stateChanged',
            isLoggedIn: false,
          }),
          { to: uiMachine.id },
        ),
        () => {
          clearUser();
          setGlobalContextProperty('knapsackMeta', undefined);
        },
        send((): AppClientDataEvents => ({ type: 'user.signedOut' }), {
          to: APP_SUB_MACHINE_IDS.appClientData,
        }),
        {
          type: 'clearing Apollo cache',
          exec: () =>
            import('@/services/util-apollo-graphql.client').then(
              ({ resetApolloClientStore }) => resetApolloClientStore(),
            ),
        },
      ],
      on: {
        'user.signOut': '.loggingOut',
        'user.infoChanged': {
          actions: assign((ctx, { info }) => {
            ctx.user.info = info;
          }),
        },
        'user.authStatusChanged': {
          actions: assign((ctx, { expiresAt }) => {
            const timesRefreshed =
              expiresAt !== ctx.user.authStatus.expiresAt
                ? ctx.user.authStatus.timesRefreshed + 1
                : ctx.user.authStatus.timesRefreshed;
            ctx.user.authStatus = {
              expiresAt,
              timesRefreshed,
            };
          }),
        },
      },
      invoke: {
        id: 'authWatcher',
        src:
          ({ user: { authStatus } }) =>
          (sendEvent) => {
            if (isCypress()) return () => {};
            if (!isBrowser) return () => {};
            userChangesBroadcastChannel.postMessage({
              type: 'broadcast.user.loggedIn',
            });
            const removeListener =
              userChangesBroadcastChannel.addMessageListener(
                'broadcast.user.loggedOut',
                () => window.location.reload(),
              );

            // 3 minutes before expiration when testing refresh tokens
            const secondsToCheckEarly = 180;
            const checkEverySeconds = 30;
            if (secondsToCheckEarly < checkEverySeconds) {
              throw new Error(
                'secondsToCheckEarly must be greater than checkEverySeconds',
              );
            }

            let { expiresAt } = authStatus;

            const updateAccessToken = async () => {
              const tokenRes = await getAccessToken();
              switch (tokenRes.type) {
                case 'success': {
                  const { accessToken, expiresAt: newExpiresAt } = tokenRes;
                  const refreshTokenUsed = expiresAt !== newExpiresAt;
                  setAccessTokenInMem(accessToken);
                  if (refreshTokenUsed) {
                    expiresAt = newExpiresAt;
                    sendEvent({
                      type: 'user.authStatusChanged',
                      expiresAt,
                    });
                  }
                  if (
                    process.env.NODE_ENV === 'development' &&
                    refreshTokenUsed
                  ) {
                    const msg =
                      'A Refresh Token was used to get a new Access Token';
                    console.log(msg, {
                      ...tokenRes,
                      accessToken: 'REDACTED',
                    });
                    createToast({
                      type: 'success',
                      message: msg,
                    });
                  }
                  break;
                }
                case 'error': {
                  console.error(tokenRes);
                  createToast({
                    type: 'error',
                    title: 'Error refreshing access token',
                    message: tokenRes.error,
                  });
                  sendEvent({ type: 'user.signOut' });
                  break;
                }
                case 'userNeedsToLoginAgain':
                case 'noSession': {
                  userChangesBroadcastChannel.postMessage({
                    type: 'broadcast.user.loggedOut',
                  });
                  sendEvent({ type: 'user.signOut' });
                  return;
                }
                default: {
                  const _exhaustiveCheck = tokenRes;
                  createToast({
                    type: 'error',
                    title: 'Error refreshing access token',
                    message: JSON.stringify(tokenRes),
                  });
                  sendEvent({ type: 'user.signOut' });
                }
              }
            };

            const timeoutId = setInterval(() => {
              const secondsLeft = getSecondsLeftUntilExpiresAt(expiresAt);
              const secondsLeftToCheckEarly = secondsLeft - secondsToCheckEarly;

              const doIt = secondsLeftToCheckEarly < checkEverySeconds;

              if (doIt) {
                updateAccessToken();
              }
            }, checkEverySeconds * 1_000);

            return () => {
              clearInterval(timeoutId);
              removeListener();
            };
          },
      },
      states: {
        loggingOut: {
          invoke: createInvokablePromise<void>({
            id: 'loggingOut',
            src: async () => {
              if (isCypress()) {
                throw new Error('Cypress logout not supported');
              }
              const paths = window.location.pathname.split('/');
              // getting the siteId from the pathname instead of context b/c we might be on a "You don't have access to this site" page with a logout button - we want them to end up back on that same page so they can try a different login
              const siteId = paths[1] === 'site' ? paths[2] : undefined;
              logout({
                returnTo: siteId ? `/site/${siteId}/${paths[3]}` : null,
              });
            },
            onDoneTarget: '#app.user.loggedOut',
            onErrorTarget: '#app.user.loggedOut',
            onErrorActions: [
              {
                type: 'sendUserMessage',
                exec(_, { data: error }) {
                  createToast({
                    type: 'error',
                    title: 'Error logging out',
                    message: error.message,
                  });
                },
              },
            ],
          }),
        },
        loadingDetails: {
          invoke: createInvokablePromise<PromiseResult<typeof loadUser>>({
            id: 'loadUserInfo',
            src: async (ctx) => {
              if (isCypress()) {
                return {
                  // having this set avoids the modal asking to set responsibility
                  responsibilityId: 'OTHER',
                  displayName: `Cypress ${ctx.user.getSiteRole('ks-sandbox')}`,
                };
              }
              return loadUser({
                userId: ctx.user?.userId,
                email: ctx.user?.email,
              });
            },
            onDoneTarget: 'loaded',
            onDoneAssignContext({ ctx, data }) {
              ctx.user.info = data;
            },
            onErrorTarget: 'loadingError',
            onErrorActions: [
              {
                type: 'sendUserMessage',
                exec(_, event) {
                  createToast({
                    type: 'error',
                    title: 'Error loading user info',
                    message: event.data.message,
                  });
                },
              },
            ],
          }),
        },
        loadingError: {},
        loaded: {},
      },
    },
  },
};
