import { tokens } from '@knapsack/toby';
import { WinWidthNames } from './types';

export function getWinWidthName(width: number): WinWidthNames {
  switch (true) {
    case width > parseInt(tokens.breakpoint.xlarge, 10):
      return 'xxlarge';
    case width > parseInt(tokens.breakpoint.large, 10):
      return 'xlarge';
    case width > parseInt(tokens.breakpoint.medium, 10):
      return 'large';
    case width > parseInt(tokens.breakpoint.small, 10):
      return 'medium';
    case width > parseInt(tokens.breakpoint.xsmall, 10):
      return 'small';
    default:
      return 'xsmall';
  }
}
