import { isObject, type Draft } from '@knapsack/utils';

/**
 * Deep merge two objects inside of an immer draft.
 * Similar to how `Object.assign()` makes smaller patches.
 * However that only merges one level deep.
 * This function will merge deeply.
 * Originally found at https://stackoverflow.com/a/34749873
 */
export function mergeDeepInImmer({
  target,
  source,
  sourceIsPartial,
}: {
  target: Draft<Record<string, any>>;
  source: Record<string, any>;
  /** Set to `true` if `source` only contains some of `target`. If `false`, then any keys in `source` that are not in `target` will be removed from the `target`. */
  sourceIsPartial: boolean;
}) {
  if (!isObject(target)) {
    throw new Error('Target must be an object in mergeDeepInImmer');
  }
  if (!isObject(source)) {
    throw new Error('Source must be an object in mergeDeepInImmer');
  }
  if (!sourceIsPartial) {
    // if keys are not in `source`, they should be removed from the `target`
    Object.keys(target).forEach((key) => {
      if (!(key in source)) {
        delete target[key];
      }
    });
  }

  Object.entries(source).forEach(([sourceKey, sourceValue]) => {
    if (isObject(sourceValue) && isObject(target[sourceKey])) {
      mergeDeepInImmer({
        target: target[sourceKey],
        source: sourceValue,
        sourceIsPartial,
      });
    } else if (target[sourceKey] !== sourceValue) {
      // only set it if it is different
      target[sourceKey] = sourceValue;
    }
  });
}
