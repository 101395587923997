import { HeadingLevel } from '../typography-config';
import { Role } from '../user-roles';
import type { PageHeaderSettings } from './page.header';

export type PageType = 'page' | 'pattern';

export const pageTabTypes = {
  designSrc: 'Design Source',
  subPage: 'Subpage',
  template: 'Template',
} as const;

export type PageTab = {
  hidden?: boolean;
  id: string;
  minRoleNeeded?: Role;
  type: keyof typeof pageTabTypes;
};

export type Page = {
  id: string;
  title: string;
  description?: string;
  /** Alternate names for use in search */
  aliases?: string[];
  settings?: {
    pageHeader?: PageHeaderSettings;
    tableOfContents?: {
      excludeBlockHeaderTitles?: boolean;
      excludeHeadings?: HeadingLevel[];
      hide?: boolean;
    };
    /**
     * @deprecated Use `tableOfContents.hide` instead
     * @todo remove deprecated hideTableOfContents setting
     * @link https://linear.app/knapsack/issue/KSP-6029/remove-deprecated-hidetableofcontents-page-setting
     */
    hideTableOfContents?: boolean;
  };
};

/**
 * Default settings for all pages
 */
export type PagesSettings = {
  tableOfContents?: {
    exclude?: HeadingLevel[];
    excludeBlockHeaderTitles?: boolean;
    hideByDefault?: boolean;
  };
  defaultPageHeader?: PageHeaderSettings;
};
