import { Draft } from 'immer';
import type {
  KsAppClientDataNoMeta,
  Page,
  PageHeaderSettings,
  PageType,
  Role,
} from '@knapsack/types';
import { BASE_PATHS } from '@/utils/constants';
import {
  SET_APP_CLIENT_DATA,
  SetAppClientData,
  RESET_APP_CLIENT_DATA,
  ResetAppClientData,
  DELETE_PAGE,
  DeletePageAction,
  AddPage,
  ADD_PAGE,
} from './shared.xstate';
import {
  duplicateNavItem,
  duplicatePage,
  mergeDeepInImmer,
  removeBlock,
} from './utils';

export type CustomPagesState = KsAppClientDataNoMeta['customPagesState'];

export const customPagesInitialState: CustomPagesState = {
  pages: {},
};

export interface pageUpdateTitleAction {
  type: 'pages.updateTitle';
  pageId: string;
  title: string;
}

export type CustomPagesActions =
  | AddPage
  | DeletePageAction
  | SetAppClientData
  | ResetAppClientData
  | pageUpdateTitleAction
  | {
      type: 'pages.updateDescription';
      description: string;
      pageId: string;
    }
  | {
      type: 'pages.duplicate';
      pageId: string;
      newId: string;
      newTitle: string;
    }
  | {
      /**
       * Be sure to send in `pages.convertToPattern.cleanup` with `setTimeout` after this event
       */
      type: 'pages.convertToPattern';
      pageId: string;
    }
  | {
      type: 'pages.convertToPattern.cleanup';
      pageId: string;
    }
  | {
      type: 'pagesOrPatterns.updateAliases';
      aliases: string[];
      id: string;
      idType: 'page' | 'pattern';
    }
  | {
      type: 'pagesOrPatterns.settings.pageHeader.reset';
      idType: 'page' | 'pattern';
      id: string;
    }
  | {
      type: 'pagesOrPatterns.settings.pageHeader.update';
      id: string;
      idType: 'page' | 'pattern';
      /** Changes will be merged into `settings.pageHeader` */
      pageHeader: Partial<PageHeaderSettings>;
    }
  | {
      type: 'pagesOrPatterns.settings.tableOfContents.update';
      id: string;
      idType: PageType;
      tableOfContents: Partial<Page['settings']['tableOfContents']>;
    }
  | {
      type: 'pagesOrPatterns.tabs.setPrivacy';
      id: string;
      idType: PageType;
      tabId: string;
      minRoleNeeded: Role;
    }
  | {
      type: 'pagesOrPatterns.tabs.toggleHidden';
      id: string;
      idType: PageType;
      tabId: string;
      hidden: boolean;
    };

export default function customPagesReducer(
  data: Draft<KsAppClientDataNoMeta>,
  action: CustomPagesActions,
): CustomPagesState {
  // providing some backwards compatibility vars for when this was Redux style: return new state shape for this state property
  // each `case` often used Immer's produce for that one `case`, now Immer is called higher up
  const state = data.customPagesState;
  const draft = data.customPagesState;
  switch (action.type) {
    case SET_APP_CLIENT_DATA: {
      data.customPagesState = action.payload.customPagesState;
      return;
    }
    case RESET_APP_CLIENT_DATA:
      data.customPagesState = customPagesInitialState;
      return;
    case ADD_PAGE: {
      // so these pieces of data don't end up in a custom-pages.yml file
      const { skipNav, parentId, ...otherProps } = action.payload;
      draft.pages[action.payload.id] = {
        ...otherProps,
        blockIds: [],
      };
      return;
    }

    case 'pages.duplicate': {
      const { pageId, newId, newTitle } = action;
      duplicatePage({
        pageId,
        newId,
        newTitle,
        data,
      });
      duplicateNavItem({
        navItemId: pageId,
        newNavItemId: newId,
        newNavItemName: newTitle,
        data,
      });
      break;
    }

    case 'pages.updateTitle': {
      const { pageId, title } = action;
      if (title.length === 0) throw new Error('The new title is empty');
      draft.pages[pageId].title = action.title;
      break;
    }

    case 'pages.updateDescription': {
      draft.pages[action.pageId].description = action.description;
      break;
    }
    case 'pages.convertToPattern': {
      const { pageId } = action;
      const page = data.customPagesState.pages[pageId];
      const { id, title, description, blockIds } = page;
      const { byId } = data.navsState;

      if (!page) throw new Error(`Cannot find page "${pageId}"`);

      data.patternsState.patterns[id] = {
        id,
        title,
        description,
        statuses: {},
        templates: [],
        tabs: [{ type: 'subPage', id: 'overview' }],
        subPages: [
          {
            id: 'overview',
            title: 'Overview',
            blockIds: [...blockIds],
          },
        ],
      };

      const newNavItem = {
        parentId: byId[pageId].parentId,
        id,
        path: `${BASE_PATHS.PATTERN}/${id}`,
        name: title,
      };

      byId[id] = newNavItem;
      // we delete the page after converting in `pages.convertToPattern.cleanup` to pattern so we can still access it via the router
      break;
    }
    case 'pages.convertToPattern.cleanup': {
      const { pageId } = action;
      // we delete the page after converting to pattern so we can still access it via the router
      delete draft.pages[pageId];
      break;
    }

    case 'pagesOrPatterns.updateAliases': {
      const { id, idType, aliases } = action;
      const pageOrPattern =
        idType === 'page' ? draft.pages[id] : data.patternsState.patterns[id];
      pageOrPattern.aliases = aliases;
      break;
    }

    case 'pagesOrPatterns.settings.tableOfContents.update': {
      const { id, idType, tableOfContents } = action;
      const pageOrPattern =
        idType === 'page' ? draft.pages[id] : data.patternsState.patterns[id];
      if (!pageOrPattern.settings) {
        pageOrPattern.settings = {};
      }
      if (!pageOrPattern.settings.tableOfContents) {
        pageOrPattern.settings.tableOfContents = {};
      }
      mergeDeepInImmer({
        target: pageOrPattern.settings.tableOfContents,
        source: tableOfContents,
        sourceIsPartial: true,
      });
      break;
    }

    case 'pagesOrPatterns.settings.pageHeader.update': {
      const { id, idType, pageHeader } = action;
      const pageOrPattern =
        idType === 'page' ? draft.pages[id] : data.patternsState.patterns[id];
      if (!pageOrPattern.settings) {
        pageOrPattern.settings = {};
      }
      if (!pageOrPattern.settings.pageHeader) {
        pageOrPattern.settings.pageHeader = {};
      }
      mergeDeepInImmer({
        target: pageOrPattern.settings.pageHeader,
        source: pageHeader,
        sourceIsPartial: true,
      });
      break;
    }

    case 'pagesOrPatterns.settings.pageHeader.reset': {
      const { id, idType } = action;
      const pageOrPattern =
        idType === 'page' ? draft.pages[id] : data.patternsState.patterns[id];
      if (!pageOrPattern.settings) {
        pageOrPattern.settings = {};
      }
      delete pageOrPattern.settings.pageHeader;
      if (Object.keys(pageOrPattern.settings).length === 0) {
        delete pageOrPattern.settings;
      }
      break;
    }

    case 'pagesOrPatterns.tabs.setPrivacy': {
      const { id, idType, minRoleNeeded, tabId } = action;
      if (idType === 'page') {
        throw new Error('Tabs are only available on patterns');
      }
      const pageOrPattern = data.patternsState.patterns[id];
      const tab = pageOrPattern.tabs.find((t) => t.id === tabId);
      if (!minRoleNeeded) {
        delete tab.minRoleNeeded;
      } else {
        tab.minRoleNeeded = minRoleNeeded;
      }
      break;
    }

    case 'pagesOrPatterns.tabs.toggleHidden': {
      const { id, idType, hidden, tabId } = action;
      if (idType === 'page') {
        throw new Error('Tabs are only available on patterns');
      }
      const pageOrPattern = data.patternsState.patterns[id];
      const tab = pageOrPattern.tabs.find((t) => t.id === tabId);

      if (hidden) {
        tab.hidden = true;
      } else {
        delete tab.hidden;
      }
      break;
    }

    case DELETE_PAGE:
      /**
       * If the page doesn't exist, return early.
       * This allows us to delete a nav item whose page has already been deleted.
       */
      if (!draft.pages[action.payload.id]) return;

      draft.pages[action.payload.id].blockIds.forEach((blockId) => {
        removeBlock({
          blockId,
          data,
        });
      });
      delete draft.pages[action.payload.id];
      return;

    default: {
      const _exhaustiveCheck: never = action;
    }
  }
}
