import {
  RouteAuthLogin,
  RouteAuthLogout,
  RouteAuthToken,
} from '@/domains/users/auth/auth-routes';
import { log } from '@/services/logger';
import { createUrlForRoute } from '../utils';

export function login({ connection }: { connection: string }) {
  if (!connection) {
    const error = new Error('No connection provided');
    log.error(`ui.auth.login: ${error.message}`, {
      error,
      evt: {
        name: 'ui.auth.login',
        outcome: 'failure',
        redirectUrl: '',
        queryParams: {},
      },
    });
    throw error;
  }
  const url = createUrlForRoute<RouteAuthLogin>({
    path: '/auth/login',
    queryParams: {
      connection,
      returnTo: window.location.href + window.location.search,
    },
    origin: window.location.origin,
  });
  window.location.assign(url.toString());
}

export const logout = (params: RouteAuthLogout['input']) => {
  if (params.returnTo && !params.returnTo.startsWith('/')) {
    throw new Error(
      `When using "logout" the param "returnTo" must be a relative path, but was: "${params.returnTo}"`,
    );
  }
  const url = createUrlForRoute<RouteAuthLogout>({
    path: '/auth/logout',
    queryParams: params,
    origin: window.location.origin,
  });
  window.location.replace(url.toString());
};

export async function getAccessToken(): Promise<RouteAuthToken['output']> {
  return fetch(
    createUrlForRoute<RouteAuthToken>({
      path: '/auth/access-token',
      origin: window.location.origin,
      queryParams: undefined,
    }),
    {
      method: 'POST' satisfies RouteAuthToken['method'],
    },
  ).then((res) => res.json());
}
