import {
  TokenDataSummary,
  TokenSrcUpdater,
  getTokenCollectionsCss,
} from '@knapsack/design-token-utils';
import { trackEvent } from '@/utils/analytics';
import type { TokenSrcGroup } from '@knapsack/types';
import { TokenEvents } from './reducers';

// Used to inform other machines of exactly what token changed and how
export interface TokenChanges {
  deleted?: {
    tokenId: string;
    originalValue: string;
  };
  renamed?: {
    oldTokenId: string;
    newTokenId: string;
  };
}

export function handleTokenUpdateEvents({
  event,
  tokensSrc,
}: {
  event: TokenEvents;
  tokensSrc: TokenSrcGroup;
}): {
  tokensSrc: TokenSrcGroup;
  tokenChanges: TokenChanges;
  tokenData: TokenDataSummary;
  tokenStyles: string;
} {
  // Send changes to parent to inform other machines, ie uiConfig
  // @todo is this still used?
  const tokenChanges: TokenChanges = {};
  const tokenSrcUpdater = new TokenSrcUpdater({
    src: event.type === 'tokens.setAll' ? event.tokensSrc : tokensSrc,
  });
  switch (event.type) {
    case 'tokens.renameToken': {
      const { type, ...data } = event;

      const { originalTokenId, destinationTokenId } =
        tokenSrcUpdater.renameToken(data);

      // Only inform other machines if the token was a color
      if (
        tokenSrcUpdater.data.tokensById[destinationTokenId].type === 'color'
      ) {
        // Inform other machines that e.g.
        //  "a-b" was renamed from "a-b" to "a-b-c"
        tokenChanges.renamed = {
          oldTokenId: originalTokenId,
          newTokenId: destinationTokenId,
        };
      }

      // @todo update `db.settings.theme` in case the token was used there
      break;
    }
    case 'tokens.delete': {
      const { tokenId } = event;

      const originalTokens = tokenSrcUpdater.deleteToken({ tokenId });

      originalTokens.forEach((originalToken) => {
        // Only inform other machines if the token was a color
        if (originalToken.type === 'color') {
          // Inform other machines that e.g.:
          // "a-b" was deleted and its original value was "#cfff"
          tokenChanges.deleted = {
            tokenId: originalToken.id,
            // Currently only supports strings, @TODO
            originalValue: originalToken.value,
          };
        }
      });

      // @todo update `db.settings.theme` in case the token was used there
      break;
    }
    case 'tokens.update': {
      const { type, ...data } = event;
      tokenSrcUpdater.updateToken(data);
      break;
    }
    case 'tokens.add': {
      const { type, ...data } = event;
      tokenSrcUpdater.createToken(data);
      break;
    }

    case 'tokens.groupAdd': {
      const { type, ...data } = event;
      tokenSrcUpdater.createGroup(data);
      break;
    }
    case 'tokens.groupRename': {
      const { type, originalGroupId, destinationGroupId } = event;
      tokenSrcUpdater.renameGroup({
        originalGroupId,
        destinationGroupId,
      });
      break;
    }
    case 'tokens.groupDelete': {
      const { type, ...data } = event;
      tokenSrcUpdater.deleteGroup(data);
      break;
    }
    case 'tokens.groupUpdate': {
      const { type, ...data } = event;
      tokenSrcUpdater.updateGroup(data);
      break;
    }
    case 'tokens.setCollectionsParentKey': {
      tokenSrcUpdater.setCollectionsParentKey(event);
      break;
    }
    case 'tokens.col.add': {
      tokenSrcUpdater.collection.create(event);
      break;
    }
    case 'tokens.col.rename': {
      tokenSrcUpdater.collection.rename(event);
      break;
    }
    case 'tokens.col.delete': {
      tokenSrcUpdater.collection.delete(event);
      break;
    }
    case 'tokens.col.mode.add': {
      tokenSrcUpdater.mode.create(event);
      break;
    }
    case 'tokens.col.mode.changeOrder': {
      tokenSrcUpdater.mode.reorder(event);
      break;
    }
    case 'tokens.col.mode.delete': {
      tokenSrcUpdater.mode.delete(event);
      break;
    }
    case 'tokens.col.mode.rename': {
      tokenSrcUpdater.mode.rename(event);
      break;
    }
    case 'tokens.col.group.add': {
      tokenSrcUpdater.collectionGroup.create(event);
      break;
    }
    case 'tokens.col.group.rename': {
      tokenSrcUpdater.collectionGroup.rename(event);
      break;
    }
    case 'tokens.col.group.delete': {
      tokenSrcUpdater.collectionGroup.delete(event);
      break;
    }
    case 'tokens.col.group.move': {
      tokenSrcUpdater.collectionGroup.move(event);
      break;
    }
    case 'tokens.col.group.convert': {
      tokenSrcUpdater.collectionGroup.convert(event);
      break;
    }
    case 'tokens.col.var.add': {
      tokenSrcUpdater.var.create(event);
      break;
    }
    case 'tokens.col.var.delete': {
      tokenSrcUpdater.var.delete(event);
      break;
    }
    case 'tokens.col.var.move': {
      tokenSrcUpdater.var.move(event);
      break;
    }
    case 'tokens.col.var.rename': {
      tokenSrcUpdater.var.rename(event);
      break;
    }
    case 'tokens.col.var.setDescription': {
      tokenSrcUpdater.var.setDescription(event);
      break;
    }
    case 'tokens.col.var.unsetModeValue': {
      tokenSrcUpdater.var.unsetModeValue(event);
      break;
    }
    case 'tokens.col.var.setModeValue': {
      tokenSrcUpdater.var.setModeValue(event);
      break;
    }
    case 'tokens.setAll':
      break;
    default: {
      const _exhaustiveCheck: never = event;
      throw new Error(
        `Not sure how to handle the event "${JSON.stringify(
          event,
        )}" in the token.updating state`,
      );
    }
  }

  setTimeout(() => {
    if (event.type.includes('add')) {
      trackEvent({
        type: 'Token Added',
        metadata: null,
      });
    } else if (event.type.includes('delete')) {
      trackEvent({
        type: 'Token Deleted',
        metadata: null,
      });
    } else if (event.type !== 'tokens.setAll') {
      trackEvent({
        type: 'Token Edited',
        metadata: null,
      });
    }
  }, 0);

  const { src, data } = tokenSrcUpdater;
  const tokenStyles = getTokenCollectionsCss({
    tokenData: data,
    minimize: true,
  });
  return { tokenChanges, tokenData: data, tokensSrc: src, tokenStyles };
}
