import { makeShortId, now, produce, type Draft } from '@knapsack/utils';
import type { DataDemo, KsAppClientDataNoMeta } from '@knapsack/types';

export function duplicateDataDemo({
  demo: topDemo,
  newDemoId: topDemoId,
  demosById,
  userId,
}: {
  demo: DataDemo;
  demosById: KsAppClientDataNoMeta['db']['demos']['byId'];
  newDemoId: string;
  userId: string;
}): {
  newDemoIds: string[];
  newDemos: Record<string, DataDemo>;
} {
  const newDemoIds: string[] = [];
  const newDemos: Record<string, DataDemo> = {};

  function dupDemoRecursive(demo: DataDemo, demoId = makeShortId()): DataDemo {
    const newDemo = produce(demo, (draft) => {
      draft.id = demoId;
      draft.meta = {
        createdBy: userId,
        createdDate: now(),
        updatedBy: userId,
        updatedDate: now(),
      };
      for (const slotDatas of Object.values(draft.data.slots || {})) {
        for (const slotData of slotDatas) {
          switch (slotData.type) {
            case 'text':
            case 'template-reference':
              break;
            case 'template-demo': {
              const slotDataDemo = demosById[slotData.demoId];
              if (!slotDataDemo) {
                throw new Error(`Demo not found - ${slotData.demoId}`);
              }
              if (slotDataDemo.type !== 'data') {
                throw new Error(
                  `Can only duplicate data demos, not ${slotDataDemo.type} - ${slotDataDemo.id}`,
                );
              }
              const { id: newDemoId } = dupDemoRecursive(slotDataDemo);
              slotData.demoId = newDemoId;
              break;
            }
            default: {
              const _exhaustiveCheck: never = slotData;
            }
          }
        }
      }
    });

    newDemoIds.push(newDemo.id);
    newDemos[newDemo.id] = newDemo;
    return newDemo;
  }

  const newDemo = dupDemoRecursive(topDemo, topDemoId);
  newDemoIds.push(newDemo.id);
  return {
    newDemoIds,
    newDemos: {
      ...newDemos,
      [newDemo.id]: newDemo,
    },
  };
}

/**
 * Recursively deletes a data demo and all nested demos within its slots
 */
export function deleteDataDemo({
  demoId,
  data,
}: {
  demoId: string;
  data: Draft<KsAppClientDataNoMeta>;
}): void {
  const demo = data.db.demos.byId[demoId];
  if (!demo) return;

  if (demo.type === 'data' && demo.data.slots) {
    // Recursively delete any nested demos in slots
    Object.values(demo.data.slots).forEach((slotDatas) => {
      slotDatas.forEach((slotData) => {
        if (slotData.type === 'template-demo') {
          deleteDataDemo({
            demoId: slotData.demoId,
            data,
          });
        }
      });
    });
  }

  delete data.db.demos.byId[demoId];
}

/**
 * Duplicates demos and returns the new demoIds.
 * If a demoId does not exist in `data.db.demos.byId`, it will be skipped.
 */
export function duplicateDemos({
  demoIds,
  demosById,
  newPatternId,
  newTemplateId,
  userId,
}: {
  demoIds: string[];
  demosById: KsAppClientDataNoMeta['db']['demos']['byId'];
  newPatternId?: string;
  newTemplateId?: string;
  userId: string;
}): { newDemoIds: string[] } {
  const existingDemoIds = demoIds.filter((id) => demosById[id]);
  const newDemoIds: string[] = [];

  for (const demoId of existingDemoIds) {
    const newDemoId = makeShortId();
    const demo = demosById[demoId];
    const meta = {
      createdBy: userId,
      createdDate: now(),
      updatedBy: userId,
      updatedDate: now(),
    };

    switch (demo.type) {
      case 'template': {
        demosById[newDemoId] = { ...demo, id: newDemoId, meta };
        newDemoIds.push(newDemoId);
        break;
      }
      case 'data': {
        const newDemo = {
          ...demo,
          id: newDemoId,
          meta,
          patternId: newPatternId || demo.patternId,
          templateId: newTemplateId || demo.templateId,
        };

        const { newDemos } = duplicateDataDemo({
          demo: newDemo,
          demosById,
          newDemoId,
          userId,
        });

        Object.assign(demosById, newDemos);
        newDemoIds.push(newDemoId);
        break;
      }
      default: {
        const _exhaustiveCheck: never = demo;
      }
    }
  }

  return { newDemoIds };
}
