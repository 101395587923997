import type {
  KnapsackCustomPage,
  KsAppClientDataNoMeta,
} from '@knapsack/types';
import type { TokenDataSummary } from '@knapsack/design-token-utils';
import type { AppContext } from '../../app/app.xstate-utils';

export enum GenericLoadingStatuses {
  idle = 'idle',
  loading = 'loading',
  success = 'success',
  failure = 'failure',
}

/**
 * @link https://github.com/redux-utilities/flux-standard-action
 */
export interface Action {
  /**
   * The `type` of an action identifies to the consumer the nature of the action that has occurred. `type` is a string constant. If two types are the same, they MUST be strictly equivalent (using `===`).
   */
  type: string;
  /**
   * The optional `payload` property MAY be any type of value. It represents the payload of the action. Any information about the action that is not the `type` or status of the action should be part of the `payload` field.
   * By convention, if `error` is `true`, the `payload` SHOULD be an error object. This is akin to rejecting a promise with an error object
   */
  payload?: Record<string, any> | typeof Error;
  /**
   * The optional `error` property MAY be set to `true` if the action represents an error.
   * An action whose `error` is true is analogous to a rejected Promise. By convention, the `payload` SHOULD be an error object.
   * If `error` has any other value besides `true`, including `undefined` and `null`, the action MUST NOT be interpreted as an error.
   */
  error?: boolean;
}

// https://redux-toolkit.js.org/tutorials/advanced-tutorial#thinking-in-thunks

// export type AppDispatch = typeof store.dispatch;
// export type AppThunk = ThunkAction<
//   void,
//   AppState,
//   unknown,
//   ToolkitAction<string>
// >;

export const SET_APP_CLIENT_DATA = 'knapsack/SET_APP_CLIENT_DATA';
export interface SetAppClientData extends Action {
  type: typeof SET_APP_CLIENT_DATA;
  payload: KsAppClientDataNoMeta;
  // yes, it's kinda tacked on by not being in `payload` but this helps by getting the `site` at the same time we set the initial app client data
  site?: AppContext['site'];
  tokenData: TokenDataSummary;
  tokenStyles: string;
}
export const RESET_APP_CLIENT_DATA = 'knapsack/RESET_APP_CLIENT_DATA';
export interface ResetAppClientData extends Action {
  type: typeof RESET_APP_CLIENT_DATA;
  payload?: undefined;
}

export const DELETE_PATTERN = 'knapsack/DELETE_PATTERN';
export interface DeletePatternAction extends Action {
  type: typeof DELETE_PATTERN;
  payload: {
    patternId: string;
  };
}

export const ADD_PATTERN = 'knapsack/ADD_PATTERN';
export interface AddPatternAction extends Action {
  type: typeof ADD_PATTERN;
  payload: {
    patternId: string;
    title: string;
    parentId?: string;
    excludeOverviewTab?: boolean;
  };
}

export const ADD_PAGE = 'knapsack/ADD_PAGE';
export interface AddPage extends Action {
  type: typeof ADD_PAGE;
  payload: Pick<KnapsackCustomPage, 'id' | 'title'> & {
    parentId?: string;
    skipNav?: boolean;
  };
}

export const DELETE_PAGE = 'knapsack/DELETE_PAGE';
export interface DeletePageAction extends Action {
  type: typeof DELETE_PAGE;
  payload: {
    id: string;
  };
}
