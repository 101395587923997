import type { KsAppClientDataNoMeta } from '@knapsack/types';
import { createFigmaUrl } from '@knapsack/utils';
import {
  ActionMap,
  ExtractActionsFromActionMap,
  getActionHandlerFromMap,
  hasActionOnMap,
} from '@/core/xstate/xstate.utils';
import type { Draft } from 'immer';

export const designSrcsActionMap = {
  'designSrcs.file.add': (
    data,
    {
      fileId,
      version,
      name,
    }: { fileId: string; version: string; name: string },
  ) => {
    if (!data.db.settings.designSrcs) {
      data.db.settings.designSrcs = {
        files: [],
      };
    }
    data.db.settings.designSrcs.files.push({
      name,
      fileId,
      version,
      type: 'figma',
      url: createFigmaUrl({ fileId, version }),
    });
  },
  'designSrcs.file.delete': (data, { fileId }: { fileId: string }) => {
    if (!data.db.settings.designSrcs) return;
    data.db.settings.designSrcs.files =
      data.db.settings.designSrcs.files.filter((f) => f.fileId !== fileId);
    Object.values(data.patternsState.patterns).forEach((pattern) => {
      Object.values(pattern.designSrcComponentsById || {}).forEach((d) => {
        if (d.fileId === fileId) {
          delete pattern.designSrcComponentsById[d.id];
          pattern.tabs = pattern.tabs.filter((t) => t.id !== d.id);
        }
      });
    });
  },
  'designSrcs.file.updateVersion': (
    data,
    {
      fileId,
      version,
      priorVersion,
    }: { fileId: string; version: string; priorVersion: string },
  ) => {
    const file = data.db.settings.designSrcs?.files?.find(
      (f) => f.fileId === fileId,
    );
    if (!file) return;
    file.version = version;
    file.priorVersion = priorVersion;
    file.url = createFigmaUrl({ fileId, version });
  },
  'designSrcs.pattern.add': (
    data,
    {
      componentId,
      fileId,
      id,
      patternId,
      title,
    }: {
      componentId: string;
      fileId: string;
      id: string;
      patternId: string;
      title: string;
    },
  ) => {
    const pattern = data.patternsState.patterns[patternId];
    if (!pattern.designSrcComponentsById) {
      pattern.designSrcComponentsById = {};
    }
    pattern.designSrcComponentsById[id] = {
      id,
      componentId,
      title,
      fileId,
    };
    pattern.tabs.push({
      id,
      type: 'designSrc',
    });
  },
  'designSrc.pattern.delete': (
    data,
    { id, patternId }: { id: string; patternId: string },
  ) => {
    const pattern = data.patternsState.patterns[patternId];
    delete pattern.designSrcComponentsById[id];
    pattern.tabs = pattern.tabs.filter((t) => t.id !== id);
  },
  'designSrcs.pattern.updateComponentId': (
    data,
    {
      id,
      fileId,
      patternId,
      componentId,
      title,
    }: {
      id: string;
      fileId: string;
      patternId: string;
      componentId: string;
      title?: string;
    },
  ) => {
    const pattern = data.patternsState.patterns[patternId];
    const component = pattern.designSrcComponentsById[id];
    component.fileId = fileId;
    component.componentId = componentId;
    if (title) component.title = title;
  },
  'designSrcs.pattern.updateTitle': (
    data,
    { id, patternId, title }: { id: string; patternId: string; title: string },
  ) => {
    const pattern = data.patternsState.patterns[patternId];
    pattern.designSrcComponentsById[id].title = title;
  },
} satisfies ActionMap;

export type DesignSrcActions = ExtractActionsFromActionMap<
  typeof designSrcsActionMap
>;

export function designSrcReducer({
  data,
  action,
}: {
  data: Draft<KsAppClientDataNoMeta>;
  action: DesignSrcActions;
}) {
  if (hasActionOnMap(action, designSrcsActionMap)) {
    getActionHandlerFromMap(action, designSrcsActionMap)(data, action);
  }
}
