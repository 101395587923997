import type { BlockConfig, KsAppClientDataNoMeta } from '@knapsack/types';
import { isDraft, makeShortId, type Draft } from '@knapsack/utils';

/**
 * Returns all blocks of a specific type.
 */
export function getBlocksByType<T extends BlockConfig['blockType']>({
  blockType,
  data,
}: {
  blockType: T;
  data: Draft<KsAppClientDataNoMeta>;
}) {
  return Object.values(data.db.blocks.byId).filter(
    (b): b is BlockConfig<T> => b.blockType === blockType,
  );
}

/**
 * Warning: leaves the blockId in the container's `blockIds` array.
 * Use `removeBlockFromContainer` if you want that removed for you.
 * This function is useful if a whole page is being deleted.
 */
export function removeBlock({
  blockId,
  data,
}: {
  blockId: string;
  data: Draft<KsAppClientDataNoMeta>;
}) {
  if (!isDraft(data)) {
    throw new Error('Data must be a draft');
  }
  const block = data.db.blocks.byId[blockId];

  if (!block) {
    console.error(`Block ${blockId} not found`);
    return;
  }

  switch (block.blockType) {
    case 'files-list': {
      const { files } = block.data;
      files.forEach((fileId) => {
        const filesListBlocks = getBlocksByType({
          blockType: 'files-list',
          data,
        });
        const isFileUsed = filesListBlocks.some(
          (b) => b.id !== blockId && b.data.files.includes(fileId),
        );
        if (!isFileUsed) {
          delete data.filesState.files[fileId];
        }
      });
      break;
    }
  }
  delete data.db.blocks.byId[blockId];
}

/**
 * Removes a blockId from a container and deletes the block from the data store.
 */
export function removeBlockFromContainer<
  ContainerType extends {
    blockIds: string[];
  },
>({
  blockId,
  container,
  data,
}: {
  blockId: string;
  /** what contains a `blocks` array to remove `blockId` from */
  container: ContainerType;
  data: Draft<KsAppClientDataNoMeta>;
}): void {
  if (!container.blockIds) return;

  removeBlock({ blockId, data });
  container.blockIds = container.blockIds.filter((bId) => bId !== blockId);
}

/**
 * Duplicates blocks and returns the new blockIds.
 * If a blockId does not exist in `data.db.blocks.byId`, it will be skipped.
 */
export function duplicateBlocks({
  blockIds,
  data,
}: {
  blockIds: string[];
  data: Draft<KsAppClientDataNoMeta>;
}): { newBlockIds: string[] } {
  const newBlockIds: string[] = [];

  // ensure each blockId exists in blocks.byId
  const existingBlockIds = blockIds.filter((bId) => data.db.blocks.byId[bId]);

  for (const blockId of existingBlockIds) {
    const newBlockId = makeShortId();
    const block = data.db.blocks.byId[blockId];
    data.db.blocks.byId[newBlockId] = {
      ...block,
      id: newBlockId,
    };
    newBlockIds.push(newBlockId);
  }
  return { newBlockIds };
}
