import {
  // type structs validate that a value has a set of properties on it, but it does not assert anything about unspecified properties. This allows you to assert that a particular set of functionality exists without a strict equality check for properties.
  type,
  string,
  Describe,
  boolean,
  optional,
  array,
  number,
  define,
} from 'superstruct';
import { isObjKey, isObject } from '../type-utils';
import type { KnapsackAssetSetsData } from './asset-sets';
import type { TokenSrcGroup } from './design-tokens-types';
import type { MetaState } from './meta';
import type { KnapsackNavsConfig } from './nav';
import type { KsFiles } from './files';
import type { BlockConfig } from './blocks';
import type { KnapsackCustomPagesData } from './custom-pages';
import type { Demo, PatternsState } from './patterns';
import { UiConfig } from '../ui-config';
import { PagesSettings } from './page';
import { BlocksSettings } from './blocks.settings';
import { DesignSrcFile } from '../design-src.types';
import { RendererId } from '../renderers';

export * from './asset-sets';
export * from './blocks';
export * from './custom-pages';
export * from './design-tokens';
export * from './design-tokens-types';
export * from './nav';
export * from './files';
export * from './meta';
export * from './page';
export * from './patterns';
export { PageHeaderSizes } from './page.header';
export type { PageHeaderSettings } from './page.header';

export interface KsAppClientData {
  patternsState: PatternsState;
  customPagesState: KnapsackCustomPagesData;
  assetSetsState: KnapsackAssetSetsData;
  navsState: KnapsackNavsConfig;
  tokensSrc: TokenSrcGroup;
  metaState?: MetaState;
  filesState: KsFiles;
  db: {
    blocks: {
      byId: Record<string, BlockConfig>;
      settings: BlocksSettings;
    };
    demos: {
      byId: Record<string, Demo>;
      // "never" for now until there are actual settings for demos
      settings: Record<string, never>;
    };
    settings: {
      title?: string;
      hideTitleInNavigation?: boolean;
      logoUrl?: string;
      favicon?: `https://${string}`;
      theme?: UiConfig;
      pages?: PagesSettings;
      patterns?: {
        hideStatusSetsOnPages?: boolean;
      };
      designSrcs?: {
        files?: DesignSrcFile[];
        hideOpenInSource?: boolean;
      };
      tokens?: {
        theming?: {
          /** Which template languages theming controls will show up for next to the components preview stage. */
          enabledRendererIds?: string[];
          /** Whether to hide the theme selector on the template stage. */
          hideThemeSelector?: boolean;
        };
      };
      prototyping?: {
        patternId: string;
        disabledRendererIds?: RendererId[];
      };
    };
  };
}

export type KsAppClientDataNoMeta = Omit<KsAppClientData, 'metaState'>;
export type KsAppClientDataAndMeta = Required<KsAppClientData>;

export function isKsAppClientDataNoMeta(
  data: unknown,
): data is KsAppClientDataNoMeta {
  if (!isObject(data)) return false;
  // TODO: bring in exhaustive key checking here so all keys are caught
  const requiredKeys: Array<keyof KsAppClientDataNoMeta> = [
    'assetSetsState',
    'customPagesState',
    'navsState',
    'patternsState',
    'tokensSrc',
    'db',
  ];
  if (!requiredKeys.every((key) => isObjKey(key, data))) return false;
  if (!isObjKey('metaState', data)) return true;
  return false;
}

export const KsAppClientDataNoMetaStruct = define<KsAppClientDataNoMeta>(
  'KsAppClientDataNoMeta',
  isKsAppClientDataNoMeta,
);

export function isKsAppClientDataAndMeta(
  data: unknown,
): data is KsAppClientDataAndMeta {
  if (!isObject(data)) return false;
  // TODO: bring in exhaustive key checking here so all keys are caught
  const requiredKeys: Array<keyof KsAppClientDataAndMeta> = [
    'assetSetsState',
    'customPagesState',
    'navsState',
    'patternsState',
    'tokensSrc',
    'metaState',
    'db',
  ];
  return requiredKeys.every((key) => isObjKey(key, data));
}

export const KsAppClientDataAndMetaStruct = define<KsAppClientDataAndMeta>(
  'KsAppClientDataAndMeta',
  isKsAppClientDataAndMeta,
);

export const KsMetaStateStruct: Describe<KsAppClientData['metaState']> = type({
  isLocalDev: boolean(),
  plugins: array(
    type({
      id: string(),
      hasContent: boolean(),
      clientPluginPath: optional(string()),
      cssPath: optional(string()),
    }),
  ),
  meta: optional(
    type({
      version: optional(string()),
      hasKnapsackCloud: boolean(),
      knapsackCloudSiteId: optional(string()),
      serverPort: optional(number()),
      websocketsPort: optional(number()),
      https: optional(boolean()),
      cacheDir: optional(string()),
      ksVersions: type({
        app: string(),
      }),
    }),
  ),
});

export const isKsMetaState = KsMetaStateStruct.is;
