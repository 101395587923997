import type { Draft } from 'immer';
import type {
  KnapsackNavItem,
  KsAppClientDataNoMeta,
  Role,
} from '@knapsack/types';
import { BASE_PATHS } from '@/utils/constants';
import { navArrayToById } from '@/domains/navs/utils/nav-data';
import { makeShortId } from '@knapsack/utils';
import {
  Action,
  ResetAppClientData,
  RESET_APP_CLIENT_DATA,
  SetAppClientData,
  SET_APP_CLIENT_DATA,
  DeletePatternAction,
  DELETE_PATTERN,
  AddPatternAction,
  ADD_PATTERN,
  AddPage,
  ADD_PAGE,
  DELETE_PAGE,
  DeletePageAction,
} from './shared.xstate';
import { pageUpdateTitleAction } from './custom-pages.xstate';
import { mergeDeepInImmer } from './utils/utils.xstate';

export type NavState = KsAppClientDataNoMeta['navsState'];

export const navInitialState: NavState = {
  byId: {},
  order: [],
};

const ADD_SECONDARY_NAV_ITEM = 'knapsack/navs/ADD_SECONDARY_NAV_ITEM';
export interface AddSecondaryNavItem extends Action {
  type: typeof ADD_SECONDARY_NAV_ITEM;
  payload: Partial<KnapsackNavItem>;
}
export function addSecondaryNavItem(
  navItem: Partial<KnapsackNavItem>,
): AddSecondaryNavItem {
  return {
    type: ADD_SECONDARY_NAV_ITEM,
    payload: navItem,
  };
}

const DELETE_NAV_ITEM = 'knapsack/navs/DELETE_NAV_ITEM';
export interface DeleteNavItemAction extends Action {
  type: typeof DELETE_NAV_ITEM;
  payload: {
    id: string;
  };
}
export function deleteNavItem(
  payload: DeleteNavItemAction['payload'],
): DeleteNavItemAction {
  return {
    type: DELETE_NAV_ITEM,
    payload,
  };
}

const TOGGLE_NAV_ITEM_VISIBILITY = 'knapsack/navs/TOGGLE_NAV_ITEM_VISIBILITY';
export interface ToggleNavItemVisibility extends Action {
  type: typeof TOGGLE_NAV_ITEM_VISIBILITY;
  payload: {
    id: string;
  };
}
export function toggleNavItemVisibility(
  payload: ToggleNavItemVisibility['payload'],
): ToggleNavItemVisibility {
  return {
    type: TOGGLE_NAV_ITEM_VISIBILITY,
    payload,
  };
}

export type NavActions =
  | AddSecondaryNavItem
  | DeleteNavItemAction
  | SetAppClientData
  | ResetAppClientData
  | AddPage
  | DeletePageAction
  | DeletePatternAction
  | AddPatternAction
  | pageUpdateTitleAction
  | ToggleNavItemVisibility
  | {
      type: 'navs.secondary.update';
      payload: KnapsackNavItem[];
    }
  | {
      type: 'navs.primary.changeOrder';
      id: string;
      index: number;
    }
  | {
      type: 'navs.item.update';
      id: KnapsackNavItem['id'];
      navItem: Partial<KnapsackNavItem>;
    }
  | {
      type: 'navs.item.setPrivacy';
      id: string;
      minRoleNeeded: Role;
    }
  /** Just used in Doctor page, assumes item exists already (just not in nav) */
  | {
      type: 'navs.item.add';
      navItem: KnapsackNavItem;
    }
  | {
      type: 'pages.moveToPage';
      pageId: string;
      destinationPageId: string;
    };

export default (
  data: Draft<KsAppClientDataNoMeta>,
  action: NavActions,
): void => {
  // Providing some backwards compatibility vars for when this was Redux style:
  // return new state shape for this state property each `case` often used Immer's
  // produce for that one `case`, now Immer is called higher up
  const state = data.navsState;
  const draft = data.navsState;
  switch (action.type) {
    case SET_APP_CLIENT_DATA: {
      const { navsState } = action.payload;
      data.navsState = navsState;
      return;
    }
    case 'navs.secondary.update': {
      // `payload` is KnapsackNavItem[], must convert to Record<string, KnapsackNavItem>
      const { byId, order } = navArrayToById(action.payload);
      mergeDeepInImmer({
        target: draft.byId,
        source: byId,
        sourceIsPartial: false,
      });
      draft.order = order;
      return;
    }
    case RESET_APP_CLIENT_DATA:
      data.navsState = navInitialState;
      return;
    case 'navs.primary.changeOrder': {
      const { id, index } = action;
      if (index === -1) return;

      let updatedOrder = [...data.navsState.order];
      updatedOrder = updatedOrder.filter((x) => id !== x);

      let topItemsPast = 0;
      let done = false;
      let isLastTopItem = false;

      updatedOrder = updatedOrder.flatMap((itemId, i, array) => {
        const item = data.navsState.byId[itemId];
        const isTopItem = item.parentId === 'root';

        if (isTopItem) {
          topItemsPast += 1;
        }

        // Check if the current index matches the target index for insertion
        if (topItemsPast === index + 1 && !done) {
          done = true;
          return [id, itemId];
        }

        // Check if it's the last top item
        isLastTopItem =
          i === array.length - 1 && topItemsPast === index && !done;

        return [itemId];
      });

      // If the item should be inserted at the end of top-level items
      if (isLastTopItem) {
        updatedOrder.push(id);
      }

      data.navsState.order = updatedOrder;
      return;
    }
    case ADD_PAGE:
    case ADD_PATTERN:
    case ADD_SECONDARY_NAV_ITEM: {
      let name = '';
      let parentId = 'root';
      let path = '';
      let skipNav = false;

      let id = makeShortId();

      switch (action.type) {
        case ADD_PATTERN: {
          const { payload: p } = action;
          parentId = p.parentId || parentId;
          name = p.title;
          path = `${BASE_PATHS.PATTERN}/${p.patternId}`;
          id = p.patternId;
          break;
        }
        case ADD_PAGE: {
          const { payload: p } = action;
          id = p.id;
          // Allows special pages (like the homepage) to skip getting added to
          // the nav file
          skipNav = p.skipNav;
          if (skipNav) break;

          parentId = p.parentId || parentId;
          name = p.title;
          path = `${BASE_PATHS.PAGES}/${p.id}`;
          break;
        }
        case ADD_SECONDARY_NAV_ITEM: {
          const { payload: p } = action;
          name = p.name;
          id = p.id;
          parentId = p.parentId || parentId;
          path = p.path;
          break;
        }
      }

      if (skipNav) {
        return;
      }

      // Adding to the bottom of the list
      // @todo: allow adding after a specific item in the list
      draft.byId[id] = {
        path: path || '', // default empty for groups
        parentId,
        id,
        name,
      };
      draft.order.push(id);
      return;
    }
    case 'pages.moveToPage': {
      const { pageId, destinationPageId } = action;
      const { byId, order } = data.navsState;
      const currentNavItem = byId[pageId];
      if (!currentNavItem)
        throw new Error(`Cannot find nav item for page "${pageId}"`);

      // If moving to primary nav, set parentId to root and return early
      if (destinationPageId === 'root') {
        byId[pageId].parentId = 'root';
        return;
      }

      const destinationNavItem = byId[destinationPageId];

      if (!destinationNavItem)
        throw new Error(`Cannot find nav item for page "${destinationPageId}"`);

      const destinationOrderIndex = order.indexOf(destinationPageId);
      const currentOrderIndex = order.indexOf(pageId);

      const newOrder = [...order];
      newOrder.splice(currentOrderIndex, 1);
      newOrder.splice(destinationOrderIndex + 1, 0, pageId);

      byId[pageId].parentId = destinationNavItem.id;
      data.navsState.order = newOrder;

      break;
    }
    case 'pages.updateTitle': {
      const { pageId, title } = action;
      Object.values(state.byId).forEach((navItem) => {
        if (
          navItem.path === `${BASE_PATHS.PAGES}/${pageId}` &&
          navItem.name !== title
        ) {
          navItem.name = title;
        }
      });
      break;
    }
    case DELETE_PAGE:
    case DELETE_PATTERN:
    case DELETE_NAV_ITEM: {
      let id = '';
      // let nav: 'secondary' | 'primary' = 'secondary';
      switch (action.type) {
        case DELETE_NAV_ITEM:
        case DELETE_PAGE: {
          id = action.payload.id;
          break;
        }
        case DELETE_PATTERN: {
          id = action.payload.patternId;
          break;
        }
      }
      if (!id) return;
      if (!draft.byId[id]) return;
      // If a nav item exists, delete it and update related nav items
      const { parentId } = draft.byId[id];
      delete draft.byId[id];
      Object.values(draft.byId).forEach((navItem) => {
        // if it has any children, lift them up one level
        if (navItem.parentId === id) {
          navItem.parentId = parentId;
        }
      });
      draft.order = draft.order.filter((navId) => navId !== id);
      return;
    }

    case TOGGLE_NAV_ITEM_VISIBILITY: {
      const { id } = action.payload;
      const navItem = draft.byId[id];

      if (navItem.hidden) {
        delete navItem.hidden;
      } else {
        navItem.hidden = true;
      }
      break;
    }

    case 'navs.item.setPrivacy': {
      const { id, minRoleNeeded } = action;
      const navItem = draft.byId[id];

      if (!minRoleNeeded) {
        delete navItem.minRoleNeeded;
      } else {
        navItem.minRoleNeeded = minRoleNeeded;
      }
      break;
    }

    case 'navs.item.update': {
      const { id, navItem: navItemUpdates } = action;
      const navItem = draft.byId[id];

      if (!navItem) {
        console.error({ action, navs: state });
        throw new Error(`Could not find nav item "${id}"`);
      }

      Object.assign(draft.byId[id], navItemUpdates);
      break;
    }
    /** Just used in Doctor page, assumes item exists already (just not in nav) */
    case 'navs.item.add': {
      const { navItem } = action;
      if (draft.byId[navItem.id]) {
        navItem.id = `${navItem.id}-${makeShortId()}`;
      }
      draft.byId[navItem.id] = navItem;
      draft.order.push(navItem.id);
      break;
    }
    default: {
      const _check: never = action;
    }
  }
};
